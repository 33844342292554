// eslint-disable-next-line
/* eslint-disable */
import { App } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/JwtService';
import { AxiosResponse, AxiosRequestConfig } from 'axios';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);

    // ApiService.vueInstance.axios.defaults.baseURL = "http://localhost:4000"          //LOCAL
    ApiService.vueInstance.axios.defaults.baseURL = "https://creamedicproduccion.herokuapp.com/";     //DESAROLLO
    //ApiService.vueInstance.axios.defaults.baseURL = "https://services.creamedicdigital.mx/";          //PRODUCCION

    // ApiService.vueInstance.axios.defaults.headers.common["token"] = `${JwtService.getToken()}`;
    // ApiService.vueInstance.axios.defaults.headers.common["Accept"] = "application/json";
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    // console.log(`TOKENN: ${JwtService.getToken()}`);
    ApiService.vueInstance.axios.defaults.headers.common[
      'token'
    ] = `${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common[
      'token_products'
    ] = `BtT3RfAMfU5f46myz32KPfL5tUrATWHQEV4idPwtQFm2dfyxuu6HerxaX3jgeDchtwwO5LPYJ6XDbXHhv4lzVVDGgWP51HYqUI1YD0pJKr9Pg4bbWvL4YrsHA8Yl1Q65mcjPiyiXKyj0DeXFgLEfucq0Q6LEGL0SYZo1BCJQXpSt9IkpiokiSKjQF21YuwvlQLFHGX5ahshCyBAB97rX8R5lUhwg8U0JsoTmMfRSBDCgHFS8bXKq0PTbKbDaGY6CExy0KxTARzOgWfwJ4265aQUDMOUy6YUkUvidifB1mwPBFL15EX0ig1V16cxxMDRBszlOSdbigKuQP1ZMDvqhc509u9Uq1H4upK1vJP4IioYgMmhgmEkc8Y0Z1abKcQSRI4HZTgiLjLzHCJRoefgAFPoyek9H8sJmuiHOv3ZzQW51fik6zx6KfFzbs20P4mv2CtUS3E2XL586upGETbt233Sq3303TtTBIkCf9sbttbxfFjpcJbzfLsexJOz7ztGj`;
    ApiService.vueInstance.axios.defaults.headers.common['Accept'] =
      'application/json';
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = '' as string
  ): Promise<AxiosResponse> {
    this.setHeader();
    console.log(resource);

    if (slug) {
      return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
    }
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    this.setHeader();
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
